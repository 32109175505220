export default function () {
  return {
    leftDrawerOpen: false,
    viewedProfiles: {
      live: [],
      demo: [],
    },
    settings: {
      has_access_for_queue: false
    },
    backendUrl: '',
    appVersion: '',
    appLatestVersion: '',
    clientDraft: {},
    currentClient: {
      id: 0,
      updated_at_formatted: '',
      unique_identifier: '',
      personal_id: '',
      first_name: '',
      last_name: '',
      name_data_quality: '',
      ssn: '',
      ssn_data_quality: '',
      date_of_birth_formatted: '',
      date_of_birth_data_quality: '',
      private: false,
      agency_id: '',
      alias: '',
      demographics: {
        client_unique_identifier: '',
        gender: '',
        ethnicity: '',
        race: [],
        middle_name: '',
        suffix: '',
        veteran_status: '',
        entered_military_service: '',
        separated_military_service: '',
        theatre_afghanistan_oef: '',
        theatre_iraq_oif: '',
        theatre_iraq_ond: '',
        theatre_korean_war: '',
        theatre_other: '',
        theatre_persian_gulf_ods: '',
        theatre_vietnam_war: '',
        theatre_world_war_ii: '',
        branch_of_military: '',
        discharge_status: '',
      }
    },
    dictionaries: {
      discharge_status: [],
      ethnicity: [],
      date_of_birth_data_quality: [],
      gender: [],
      race: [],
      military_branch: [],
      name_data_quality: [],
      ssn_data_quality: [],
      veteran_status: [],
      theatre_afghanistan_oef: [],
      theatre_iraq_oif: [],
      theatre_iraq_ond: [],
      theatre_korean_war: [],
      theatre_other: [],
      theatre_persian_gulf_ods: [],
      theatre_vietnam_war: [],
      theatre_world_war_ii: [],
      household_types: [
        "Not Set",
        "Husband",
        "Wife",
        "Daughter",
        "Son",
        "Father",
        "Mother",
        "Sister",
        "Brother",
        "Roommate",
        "Grandchild",
        "Aunt",
        "Uncle",
        "Niece",
        "Nephew",
        "Cousin",
        "Grandparent",
        "Significant Other",
        "Domestic Partner",
        "Spouse",
        "Stepson",
        "Other",
        "Stepdaughter",
        "Child",
        "Parent",
        "Sibling",
        "Guardian",
      ]
    },
    title: {
      line_1: "",
      line_2: "",
    },
    agencies: [],
    user: {
      id: 0,
      full_name: "",
      email: "",
      agency_id: 0,
      agency: {
        id: 0,
        name: ''
      },
      status: "",
      password_expires_at: null,
      clarity_domain: null,
      clarity_login: "",
      clarity_password: "",
      clarity_login_error_message: '',
      failed_clarity_login_tries: 0,
      clarity_credentials_last_check_at: null,
      clarity_credentials_valid: false,
      has_access_for_live_only: false,
      last_visit_date: "1970-01-01T20:15:34.000000Z",
      created_at: "1970-01-01T20:12:01.000000Z",
      updated_at: "2020-12-02T00:30:11.000000Z",
      api_token: "",
      access_mode: '',
      has_access_to_live: false,
      has_access_for_p_registry: false,
      has_access_for_p_resources: false,
    }
  }
}
