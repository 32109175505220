<template>
  <div id="q-app">
    <router-view />
<!--    <doorbell/>-->
    <axios-interceptors></axios-interceptors>
  </div>
</template>
<script>

import {storeFcmToken} from "boot/axios";

import AxiosInterceptors from "components/AxiosInterceptors";

export default {
  name: 'App',
  components: {AxiosInterceptors},
  data(){
    return {
      heartbeatInterval: null
    }
  },

  beforeDestroy() {
    if(this.heartbeatInterval){
      clearInterval(this.heartbeatInterval)
    }
  },
  async mounted() {

    this.heartbeatInterval = setInterval(() => {
      this.heartbeat()
    }, 5000)

    window.handleOpenURL = (url) => {
      // alert(url)
      this.$router.push(url.replace('helpmaster://', '/'))
    }

    try {
      // eslint-disable-next-line no-undef
      codePush.sync(null, { updateDialog: false, installMode: InstallMode.IMMEDIATE });
    } catch (e) {

    }

    try {
      // eslint-disable-next-line no-undef
      FCM.requestPushPermission();

      if (this.$store.state.mainStore.user.id) {
        // eslint-disable-next-line no-undef
        let token = await FCM.getToken()
        storeFcmToken(token)
      }

      // eslint-disable-next-line no-undef
      FCM.onTokenRefresh(function(token){
        storeFcmToken(token)
      });
    } catch {

    }

    this.syncUser(false)

    let that = this

    // refresh timer for active job when app application pulled out from the background
    document.addEventListener("resume", () => {
      that.syncUser(false)
    }, false);

    // try {
    //   window.screen.orientation.lock('portrait');
    // } catch {
    //
    // }

    window.addEventListener('keyboardWillShow', function () {
      window.$('body').addClass('keyboard-open')
      window.$('.q-page-sticky .q-btn').fadeOut()

    });

    window.addEventListener('keyboardWillHide', function () {
      window.$('body').removeClass('keyboard-open')

      window.$('.q-page-sticky .q-btn').fadeIn()
    });

    this.$store.commit('mainStore/clearViewedProfiles')
  },
  methods: {
    heartbeat(){
      this.$axios.get(this.$apiUrlUsers + '/auth/heartbeat')
    },
  }
};
</script>
