
const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '/', meta: { title: '', hideHeader: true, hideFooter: true }, name: 'root', component: () => import('pages/Root.vue') }, // should be root component to determine where to go
      { path: '/login', meta: { title: '', hideHeader: true, hideFooter: true }, name: 'login', component: () => import('pages/Login.vue') },
      { path: '/signup', meta: { title: '', hideHeader: true, hideFooter: true }, name: 'signup', component: () => import('pages/SignUp.vue') },
      { path: '/logout', meta: { title: '', hideHeader: true, hideFooter: true }, name: 'logout', component: () => import('pages/Logout.vue') },
      { path: '/forgot_password', meta: { title: 'Forgot password', hideHeader: true, hideFooter: true }, name: 'forgot_password', component: () => import('pages/ForgotPassword.vue') },
      { path: '/reset_password/:token', meta: { title: 'Reset password', hideHeader: true, hideFooter: true }, name: 'reset_password', component: () => import('pages/ResetPassword.vue') },
      { path: '/profile', meta: { title: 'HelpMaster Profile', hideHeader: false, hideFooter: true }, name: 'profile', component: () => import('pages/Profile.vue') },
      { path: '/clarity_settings', meta: { title: 'Clarity settings', hideHeader: false, hideFooter: true }, name: 'clarity_settings', component: () => import('pages/ClaritySettings.vue') },
      { path: '/clarity_login_blocker', meta: { title: 'Clarity Log In Failed', hideHeader: false, hideFooter: true }, name: 'clarity_login_blocker', component: () => import('pages/ClarityLastLoginFailedPage.vue') },
      { path: '/waiting_for_verification', meta: { title: 'Waiting for verification', hideHeader: true, hideFooter: true }, name: 'waiting_for_verification', component: () => import('pages/WaitingForVerification.vue') },
      { path: '/waiting_for_user_verification', meta: { title: 'Waiting for user verification', hideHeader: true, hideFooter: true }, name: 'waiting_for_user_verification', component: () => import('pages/WaitingForUserVerification') },
      { path: '/app_on_maintenance', meta: { title: 'HelpMaster in on Maintenance', hideHeader: true, hideFooter: true }, name: 'app_on_maintenance', component: () => import('pages/AppOnMaintenance.vue') },
        //
      { path: '/dashboard', meta: { title: 'Home', hideHeader: false, hideFooter: true }, name: 'dashboard', component: () => import('pages/Dashboard.vue') },
      { path: '/queue', meta: { title: 'My Changes', hideHeader: false, hideFooter: true }, name: 'queue', component: () => import('pages/QueueItemsList') },
      { path: '/client', meta: { title: 'New Client', hideHeader: false, hideFooter: true }, name: 'new_client_profile', component: () => import('pages/ClientProfile.vue') },
      { path: '/client_history', meta: { title: 'Services', hideHeader: false, hideFooter: true }, name: 'enrollments', component: () => import('pages/EnrollmentsHistory.vue') },
      { path: '/client_assessments', meta: { title: 'Assessments', hideHeader: false, hideFooter: true }, name: 'assessments', component: () => import('pages/ClientAssessments.vue') },
      { path: '/client_notes', meta: { title: 'Client Notes', hideHeader: false, hideFooter: true }, name: 'client_notes', component: () => import('pages/ClientNotes.vue') },
      { path: '/client_files', meta: { title: 'Client Files', hideHeader: false, hideFooter: true }, name: 'client_files', component: () => import('pages/FilesPage.vue') },
      { path: '/client_contacts', meta: { title: 'Client Contact', hideHeader: false, hideFooter: true }, name: 'client_contacts', component: () => import('pages/ClientContacts.vue') },
      { path: '/client_locations', meta: { title: 'Client Locations', hideHeader: false, hideFooter: true }, name: 'client_locations', component: () => import('pages/Locations.vue') },
      { path: '/client_household', meta: { title: 'Household Members', hideHeader: false, hideFooter: true }, name: 'household_members', component: () => import('pages/HouseholdMembers.vue') },
      { path: '/client_programs', meta: { title: 'Programs', hideHeader: false, hideFooter: true }, name: 'programs', component: () => import('pages/Programs.vue') },
      { path: '/client_services', meta: { title: 'Services', hideHeader: false, hideFooter: true }, name: 'services', component: () => import('pages/ClientServices.vue') },
      { path: '/privacy', meta: { title: 'Privacy', hideHeader: false, hideFooter: true }, name: 'privacy', component: () => import('pages/PrivacyAndRoi.vue') },
      { path: '/client', meta: { title: 'Client Profile', hideHeader: false, hideFooter: true }, name: 'client_profile', component: () => import('pages/ClientProfile.vue') },
      { path: '/caseload', meta: { title: 'Caseload', hideHeader: false, hideFooter: true }, name: 'caseload', component: () => import('pages/Caseload.vue') },
      { path: '/my_cases', meta: { title: 'My Cases', hideHeader: false, hideFooter: true }, name: 'my_cases', component: () => import('pages/MyCases') },

      { path: '/bulk_notifications', meta: { title: 'Alert System', hideHeader: false, hideFooter: true }, name: 'bulk_notifications', component: () => import('pages/BulkNotifications.vue') },

      { path: '/p_registry', meta: { title: 'Registry', hideHeader: false, hideFooter: true }, name: 'p_registry', component: () => import('pages/PregistryPage.vue') },
      { path: '/p_resources', meta: { title: 'Resources', hideHeader: false, hideFooter: true }, name: 'p_resources', component: () => import('pages/PresourcesPage.vue') },
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
