import Notify from 'quasar/src/plugins/Notify.js';

Notify.setDefaults({
  position: 'top',
  timeout: 10000,
  progress: true,
  textColor: 'white',
  actions: [{ label: 'Close', color: 'white' }]
})

Notify.registerType('required-fields', {
  progress: true,
  color: 'brown',
  html: true,
  textColor: 'dark',
  closeBtn: true,
  timeout: 90000,
  classes: 'bg-grey-1 text-pre'
})
