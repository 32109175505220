import Vue from 'vue'
import VueRouter from 'vue-router'
import VuePageStack from 'vue-page-stack';

import routes from './routes'
import Loading from 'quasar/src/plugins/Loading'

Vue.use(VueRouter)

Vue.prototype.$showFooter = false
Vue.prototype.$showHeader = false
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function (/* { store, ssrContext } */) {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  })

  Router.afterEach((to, from) => {
    Loading.hide()

    if (to.meta) {
      Vue.prototype.$footerType = to.meta.footer
      Vue.prototype.$showFooter = !to.meta.hideFooter
      Vue.prototype.$showHeader = !to.meta.hideHeader
    } else {
      Vue.prototype.$showFooter = false
      Vue.prototype.$showHeader = false
    }
  })

  Vue.use(VuePageStack, { router: Router });

  return Router
}
