export function leftDrawerOpen (state, leftDrawerOpen) {
  state.leftDrawerOpen = leftDrawerOpen
}

export function user(state, user){
  state.user = user
}

export function addViewedProfile(state, client){
  let user = state.user
  let mode = user.access_mode

  state.viewedProfiles[mode] = state.viewedProfiles[mode] || []

  let i = state.viewedProfiles[mode].findIndex(p => p.id === client.id)

  if(i > -1){
    state.viewedProfiles[mode].splice(i, 1)
  } else {
    state.viewedProfiles[mode].unshift(client)
  }
}

export function clearViewedProfiles(state){
  state.viewedProfiles = []
}

export function agencies(state, agencies){
  state.agencies = agencies
}

export function deleteViewedProfiles(state, index){
  let user = state.user
  let mode = user.access_mode

  state.viewedProfiles[mode].splice(index, 1)
}

export function currentClient(state, currentClient){
  state.currentClient = currentClient
}

export function dictionaries(state, dictionaries){
  state.dictionaries = {...dictionaries}
}

export function title(state, title){
  state.title = title
}

export function backendUrl(state, backendUrl){
  state.backendUrl = backendUrl
}

export function settings(state, settings){
  state.settings = settings
}

export function clientDraft(state, clientDraft){
  state.clientDraft = clientDraft
}

export function setting(state, payload){
  state.settings[payload.option] = payload.value
}

export function appVersion(state, appVersion){
  state.appVersion = appVersion
}

export function appLatestVersion(state, appLatestVersion){
  state.appLatestVersion = appLatestVersion
}

