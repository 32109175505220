import Vue from 'vue'
import axios from 'axios'
import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import Platform from 'quasar/src/plugins/Platform.js';

Vue.prototype.$axios = axios

Vue.prototype.$apiUrl = 'https://hmis.helpmaster.io/api'
// Vue.prototype.$apiUrl = 'https://helpmaster.test/api'
Vue.prototype.$apiUrlUsers = 'https://hm-login.getomfm.com/api'
// Vue.prototype.$apiUrlUsers = 'https://helpmaster.test/api'

import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

import JsonViewer from 'vue-json-viewer'
// Import JsonViewer as a Vue.js plugin
Vue.use(JsonViewer)

export function copy(object){
  if(!object){
    return object
  }

  return JSON.parse(JSON.stringify(object))
}

export function debounce (fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export function convertFrom24To12Format (time24) {
  const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
  const period = +sHours < 12 ? 'am' : 'pm';
  const hours = +sHours % 12 || 12;

  return `${hours}:${minutes} ${period}`;
}

export function setAccessToken (token = null) {
  if (token) {
    LocalStorage.set('access_token', token)
  } else {
    token = LocalStorage.getItem('access_token')
  }

  Vue.prototype.$axios.defaults.headers.common.Authorization = 'Bearer ' + token
}

setAccessToken()

export async function getUser () {
  return Vue.prototype.$axios.get(Vue.prototype.$apiUrl + '/user/me').then((response) => {
    // Get Token
    return response.data
  })
}

export async function storeFcmToken (token) {
  if (!token) {
    return
  }

  return Vue.prototype.$axios.post(Vue.prototype.$apiUrl + '/auth/store_token', {
    'token': token, 'platform': Platform.is.platform
  }).then((response) => {
    return response.data
  })
}
