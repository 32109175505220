import Vue from 'vue'
import colors from 'quasar/src/utils/colors.js';;

Vue.mixin({
  methods: {
    loadDictionaries() {
      this.axiosGet('/clients/dictionaries', r => {
        this.$store.commit('mainStore/dictionaries', r.data)
      }, false)
    },
    notify(text, type = 'positive', timeout = 3000) {
      this.$q.notify({
        message: text,
        type: type,
        timeout: timeout
      })
    },
    axiosGet(uri, successCallback, loading = true, errorCallback, options) {
      if (loading) {
        this.$q.loading.show()
      }

      let promise = this.$axios.get(this.apiUrl + uri, options);

      if (typeof successCallback === "function") {
        promise.then(successCallback);
      }

      promise.finally(() => {
        if (loading) {
          this.$q.loading.hide()
        }

        if (typeof errorCallback === "function") {
          errorCallback()
        }
      })

      return promise
    },

    axiosPost(uri, data, successCallback, loading = true, errorCallback) {
      if (loading) {
        this.$q.loading.show()
      }

      let promise = this.$axios.post(this.apiUrl + uri, data, data ? data.headers : undefined);

      if (typeof successCallback === "function") {
        promise.then(successCallback);
      }

      promise.finally(() => {
        if (loading) {
          this.$q.loading.hide()
        }

        if (typeof errorCallback === "function") {
          errorCallback()
        }
      })

      return promise
    },
    setClientPageSubTitle(title) {
      this.$store.commit('mainStore/title', {
        line_1: this.currentClient ? this.currentClient.full_name : '',
        line_2: title
      })
    },
    openClientProfile(client) {
      this.$router.push('/client?id=' + client.id)
    },
    async syncUser(loading = true) {
      if (loading) {
        this.$q.loading.show()
      }

      return this.$axios.get(this.$apiUrlUsers + '/user/me').then(r => {
        if(!r.data){
          this.$store.commit('mainStore/backendUrl', this.$apiUrl)
          return
        }

        this.loadDictionaries();

        this.$store.commit('mainStore/user', r.data)
        this.$store.commit('mainStore/backendUrl', r.data.backend_url)

        let color = r.data.access_mode == 'demo' ? '#0f6fff' : '#FE9315'
        colors.setBrand('primary', color)

        if(this.currentUser.has_access_for_both_hm_and_street_outreach || !this.currentUser.has_access_for_p_registry){
          this.getPossibleAgencies()
        }
      }).finally(() => {
        if (loading) {
          this.$q.loading.hide()
        }
      })
    },
    getPossibleAgencies(){
      this.axiosGet('/user/possible_agencies',r => {
        this.$store.commit('mainStore/agencies', r.data)
      }, false)
    },
    defaultUrlToRedirect(){
      if(!this.currentUser || !this.currentUser.id){
        return '/login'
      }

      if(this.currentUser.has_access_for_both_hm_and_street_outreach || !this.currentUser.has_access_for_street_outreach){
        return '/dashboard'
      }

      // todo add urls here in future

      if (this.currentUser.has_access_for_p_registry) {
        return '/p_resources'
      }

      if (this.currentUser.has_access_for_p_registry) {
        return '/p_registry'
      }

      return '/profile'
    },
    getAccessForQueue(){
      this.$axios.get(this.$apiUrlUsers + '/settings/has_access_for_queue').then((r) => {
        this.$store.commit('mainStore/setting', {
          option: 'has_access_for_queue',
          value: r.data.result
        })
      })
    },
    getAccessForReferals(){
      this.$axios.get(this.$apiUrlUsers +'/settings/referals_enabled').then((r) => {
        this.$store.commit('mainStore/setting', {
          option: 'referals_enabled',
          value: r.data.result
        })
      })
    },
  },
  computed: {
    appLatestVersion(){
      return this.$store.state.mainStore.appLatestVersion
    },
    appVersion: {
      get(){
        return this.$store.state.mainStore.appVersion
      },
      set(val){
        this.$store.commit('mainStore/appVersion', val)
      }
    },
    isMobile(){
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    apiUrl(){
      return this.$store.state.mainStore.backendUrl || this.$apiUrl
    },
    currentClientMissingRoi() {
      return this.currentUser.id
          && this.currentClient
          && this.currentClient.id
          && typeof this.currentClient.roi_granted === "boolean"
          && !this.currentClient.roi_granted
    },
    requiredRules() {
      return [v => (v !== null && typeof v !== "undefined" && v !== "") || 'Value is required']
    },
    currentClient() {
      try {
        return this.$store.state.mainStore.currentClient || {}
      } catch {
        return {}
      }

    },
    currentUser() {
      try {
        return this.$store.state.mainStore.user || {}
      } catch {
        return {}
      }
    },
    recentProfiles() {
      let user = this.$store.state.mainStore.user

      if(!user.id){
        return []
      }

      return this.$store.state.mainStore.viewedProfiles[user.access_mode] ? this.$store.state.mainStore.viewedProfiles[user.access_mode] : []
    }
  }
})
