import Vue from 'vue'

Vue.prototype.gmapKey = 'AIzaSyCyxMBFOO-JFaYVQYslozESMzne5w75FlM'

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: Vue.prototype.gmapKey,
    libraries: 'places,geometry,directions,visualization', // Required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    // v: 3.38
  },
  installComponents: true
})
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
Vue.component('gmap-cluster', GmapCluster);

